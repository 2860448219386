*, *::before, *::after {
    box-sizing: border-box;
}

:root {
    --header-height: 60px;
    --sidebar-width: 220px;
    --sidebar-left: 0px;
    --blue: #007bff;
    --white: #ffffff;
    --color-bg: #eef3f5;
    --box-color-bg: white;
    --color-border: #d5dee4;
    --color-primary-blue: #0c4feb;
    --color-green-dark: #1ccb52;
    --color-dark-blue: #10355a;
    --color-dark-grey: #8e8e8d;
    --color-red: #dc3545;
    --color-red-100: #ffcdd2;
  }

.minw-200 { min-width: 200px; }
.minw-300 { min-width: 300px; }
.minw-1000 { min-width: 1000px; }

.maxw-200 { max-width: 200px; }
.maxw-700 { max-width: 700px; }

.padding-10 { padding: 10px; }
.color-red { color: red }
.strong-red-text { font-weight: bold; color: red; }
.normal-red-text { font-weight: normal; color: red; }
.cursor-help { cursor: help; }

.messageModalBody {
    white-space: pre-wrap;
}

.versionContainer {
    padding: 10px;
    font-size: 14px;
    font-style: italic;
    display: flex;
    position: fixed;
    right: 0;
    bottom: 0;
}

.loading-screen {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99999;
    background-color: hsla(0, 0%, 0%, 0.7);
}

.loading-content {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: calc(100vw - var(--sidebar-width) - var(--sidebar-left) );
    height: calc(100vh - var(--header-height));
    position: fixed;
    top: var(--header-height);
    left: calc(var(--sidebar-left) + var(--sidebar-width));
    z-index: 9999;
    background-color: hsla(0, 0%, 0%, 0.1);
}
  
body {
    margin: 0;
    width: 100%;
    height: 100%;
    min-width: 900px;
    background-color: var(--color-bg);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

#root {
    display: block;
}

.textQuiet {
    color: var(--color-dark-grey);
    font-weight: normal;
}

.editModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    display: flex;
    flex-direction: column;
    min-width: 500px;
}

.editModal:focus {
    outline: 0
}

.editModalTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    font-weight: bold;
    color: var(--color-dark-blue);
    border-bottom: 1px solid var(--color-border);
    padding: 10px;
}

.editModalInputContainer {
    padding: 10px;
    border-bottom: 1px solid var(--color-border);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.textError {
    font-weight: bold;
    padding-top: 10px;
    padding-left: 2px;
    color: var(--color-red);
}

.editModalControls {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 10px;
}

.editModalInputTable {
    display: table;
    border-collapse: collapse;
  border-style: hidden;
}

.editModalInputTable tr th {
    border: 1px solid var(--color-border);
    padding: 10px;
    
}

.editModalInputTable tr.hidden {
    
    display: none;
    
}

.editModalInputTable tr th:first-child {
    text-align: end;
    font-weight: normal;
    min-width: 150px;
}

/* --- LOGIN SCREEN ---*/
.loginScreen {
    width: 100vw;
    height: 100vh;
    background-color: #f0f2f5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginWrapper {
    width: 800px;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.loginLogoBox {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.loginLogoBox img {
    width: 300px;
}

.loginInputBox {
    display: flex;
    flex: 1;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
}

header {
    position: sticky;
    left: 0px;
    top: 0px;
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    height: var(--header-height);
    width: 100%;
    background-color: var(--color-bg);
    box-shadow: 0 3px 6px 0 rgb(16 53 90 / 25%);
    z-index: 1000;
}


.headerProfile {
    display: flex;
    flex-direction: row;
    
    justify-content: center;
    align-items: center;
    
    height: 40px;
    border: 1px solid #bbc7d0;
    border-radius: 5px;
    
    
    color: #10355a;
    background-color: white;
    overflow: hidden;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.headerProfile .leftBox {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    /* cursor: pointer; */
}

.headerProfile .centerBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 10px;
    padding-right: 10px;
    border-left: 1px solid #bbc7d0;
    border-right: 1px solid #bbc7d0;
}

.headerProfile .centerBox .nameContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 14px;
    height: 20px;
    font-weight: bold;
}

.headerProfile .centerBox .usernameContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 20px;
    font-size: 12px;
}

.headerProfile .rightBox {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.headerProfile .rightBox:hover {
    color: var(--blue);
}

.headerButton {
    display: flex;
    background-color: white;
    width: 40px;
    height: 40px;
    border: 1px solid #bbc7d0;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #10355a;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.headerButton:hover {
    color: var(--blue);
}

.sidebar {
    position: fixed;
    left: var(--sidebar-left);
    top: var(--header-height);
    float: left;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: var(--color-bg);
    width: var(--sidebar-width);
    transition: all 0.2s linear;
    box-shadow: 3px 0 5px 0 rgb(16 53 90 / 25%);
    border-right: 1px solid #bbc7d0;
    height: calc(100vh - var(--header-height));
    z-index: 900;
}

.sidebar-top {
    display: flex;
    width: 100%;
    padding: 10px;
    font-weight: bold;
    font-size: 16px;
    color: var(--color-dark-blue);
}

.sidebar-main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    flex: 1;
}

.sidebar-bottom {
    display: flex;
    width: 100%;
    height: 100px;
}

.sidebarMenuItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #bbc7d0;
    color: #10355a;
    gap: 10px;
}

.sidebarMenuItem.inactive {
    cursor: pointer;
}

.sidebarMenuItem.active {
    background-color: var(--color-primary-blue);
}

.sidebarMenuItem.active span {
    font-weight: bold;
    color: white;
}

.sidebarMenuItem.inactive:hover {
    color: white;
    background-color: var(--color-primary-blue);
}

.sidebarMenuItem:first-child {
    border-top: 1px solid #bbc7d0;
}

.sidebarSubMenuItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    width: 100%;
    height: 35px;
    border-bottom: 1px solid #bbc7d0;
    color: #10355a;
    gap: 10px;
}

.sidebarSubMenuContainer {
    overflow: hidden;
    height: 0;
}

.sidebarSubMenuContainer.active {
    height: auto;
}

.sidebarMenuIconGroupChevron {
   
}

.sidebarMenuIconGroupChevron.active {
    transform:rotate(90deg);
    -ms-transform:rotate(90deg);
    -webkit-transform:rotate(90deg);
}


.content {
    display: flex;
    flex-direction: column;
    width: calc(100vw - var(--sidebar-width) - var(--sidebar-left) );
    
    height: calc(100vh - var(--header-height));
    position: fixed;
    top: var(--header-height);
    left: calc(var(--sidebar-left) + var(--sidebar-width));
    transition: all 0.2s linear;
    background-color: var(--color-bg);
    overflow: auto;
}

.contentContainer {
    background-color: white;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.content-top-bar {
    position: sticky;
    top:0;
    width: 100%;
    height: max-content;
    background-color: var(--color-bg);
    padding: 10px;
    z-index: 10;
}

.content-main {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

.dataTableContainer {
    display: flex;
    flex: 1;
}

.functionBar {
    display: flex;
    flex-direction: column;
    min-width: 1000px;
}

.functionBarBreadcrumb {
    padding: 0px 10px 5px 0px;
}

.beardcrumb-item {
    font-size: 15px;
    color: var(--color-dark-grey);
}

.beardcrumb-item-last {
    font-size: 15px;
    color: var(--color-dark-blue);
    font-weight: bold;
}

.beardcrumb-separator {
    font-size: 15px;
    color: var(--color-dark-grey);
    padding: 0px 5px 0px 5px;
}

.functionBarIcons {
    background-color: white;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.functionBarButton {
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border: 1px solid #bbc7d0;
    border-radius: 5px;
    font-size: 28px;
    cursor: pointer;
    color: #10355a;
    background-color: white;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.functionBarButtonIconText {
    display: flex;
    flex-direction: row;
    padding-left: 5px;
    justify-content: flex-start;
    align-items: center;
    height: 35px;
    border: 1px solid #bbc7d0;
    border-radius: 5px;
    cursor: pointer;
    color: #10355a;
    background-color: white;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.functionBarButtonIconText.active {
    border: 1px solid var(--color-dark-blue);
    background-color: var(--color-bg);
}

.functionBarButtonIconText span {
    font-size: 16px;
    padding-right: 10px;
    padding-left: 5px;
}

.functionBarButton:not(.active):hover, .functionBarButtonIconText:not(.active):hover {
    background-color: var(--color-bg);
    border: 1px solid hsl(206, 58%, 77%);
}

.functionBarBtnIcon {
    padding: 0;
    margin: 0;
    width: auto;
    height: 22px;
}

.functionBarSearchContainer {
    width: 200px;
    height: 35px;
    border: 1px solid #bbc7d0;
    border-radius: 5px;
    font-size: 28px;
    cursor: pointer;
    color: #10355a;
    background-color: white;
}

.functionBarSearchInput {
    padding: 0!important;
    border: 0!important;
    outline: none!important;
}

.functionBarSearchInput:focus {
    outline: none!important;
}

.box {
    background-color: var(--box-color-bg);
    border-radius: 5px;
}

.form-group-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 15px;
    border-bottom: 1px solid var(--color-border);
}

.form-group-label {
    color: var(--color-dark-blue);
    font-weight: bold;
}

.form-group-icon {
    font-size: 25px;
    color: var(--color-dark-blue);
}

.form-group-row.no-border {
    border-bottom: 0;
}

.form-label {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 0!important;
    min-width: 300px;
    text-align: end;
    padding-right: 10px;
    color: var(--color-dark-blue);
    font-weight: bold;
}

.dataContainer {
    background-color: white;
    display: flex;
    padding: 10px;
    border-radius: 5px;
    
}

.form-check-container {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.list-data-title {
    color: var(--color-dark-blue);
    font-weight: bold;
}

.list-data-small {
    font-size: 15px;
    color: var(--color-dark-grey);
}


/** DATA GRID */
.dataCard:not(:last-child) {   
    margin-bottom: 10px;
}
.noDataCard {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: var(--color-dark-grey);
}
.tableDataGrid {
    display:table;
    width: 100%;
}
.tableDataGrid tr.hidden {
    display: none;
}
.tableDataGrid tr:hover:not(.titleRow) {
    background-color: var(--color-bg);
}
.tableDataGrid tr:not(:last-child) {
    border-bottom: 1px solid var(--color-border);
}
.tableDataGrid th {
    padding: 10px;
    color: var(--color-dark-blue);
    display: table-cell;
}
.tableDataGrid tr:not(.titleRow) th:first-child {
   width: 300px;
   font-weight: normal;
}
.tableDataGrid tr:not(.titleRow) th:nth-child(2) {
    border-left: 1px solid var(--color-border);
}
.tableDataGrid .titleRow .controlledContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.tableDataGrid .titleRow .titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.tableDataGrid .titleRow .titleContainer .titleRowIcon {
    font-size: 25px;
}
.tableDataGrid .titleRow .controlsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}
.tableDataGrid .dataRow .titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
}
.tableDataGrid .dataRow .editIcon {
    visibility: hidden;
    opacity: 0;
    width: auto;
    height: 20px;
    cursor: pointer;
}
.tableDataGrid tr.dataRow:hover .editIcon {
    visibility: visible;
    opacity: 1;
}
.tableDataGrid .editIcon:hover {
    color: var(--blue);
}





.customersListDataTable {
    display:table;
    width: 100%;
    table-layout: fixed;
}
.customersListDataTable tr:first-child th {
    font-weight: normal;
    font-size: 15px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 6px;
    color: var(--color-dark-blue);
}

.customersListDataTable tr:first-child th div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    cursor: pointer;
}

.customersListDataTable tr:first-child th div .noPointer {
    cursor: auto;
}

.customersListDataTable tr th:first-child {
    width: 20px;
    padding-left: 10px;
}
.customersListDataTable tr th:first-child div {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}
.customersListDataTable tr th:nth-child(2) {
    width: auto;
}
.customersListDataTable tr th:nth-child(3) {
    width: auto;
}
.customersListDataTable tbody tr:hover:not(:first-child) {
    background-color: var(--color-bg);
}
.customersListDataTable tbody tr:not(:last-child) {
    border-bottom: 1px solid var(--color-border);
}
.customersListDataTable tr:not(:first-child) th:not(:first-child) {
    padding: 6px;
    color: var(--color-dark-blue);
    vertical-align: middle;
    font-size: 15px;
}
.customersListDataTable tr:not(:first-child) {
    cursor: pointer;
}
.customersListDataTable th .nameContainer {
    display: inline-flex;
    flex-direction: column;
}
.customersListDataTable th .nameContainer span:first-child {
    font-weight: bold;
}
.customersListDataTable th .nameContainer span:nth-child(2) {
    font-size: 14px;
    font-weight: normal;
}
.customersListDataTable th .iconContainer {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.customersListDataTable th .addressContainer {
    display: inline-flex;
    flex-direction: column;
}
.customersListDataTable th .addressContainer span:first-child {
    font-weight: bold;
}
.customersListDataTable th .addressContainer span:nth-child(2) {
    font-size: 14px;
    font-weight: normal;
}

.customersListDataTable .sortIcon {
    font-size: 26px;
}




.usersListDataTable {
    display:table;
    width: 100%;
    table-layout: fixed;
}
.usersListDataTable tr:first-child th {
    font-weight: normal;
    font-size: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 10px;
    color: black;
}
.usersListDataTable tr th:first-child {
    width: 40px;
    padding-left: 10px;
}
.usersListDataTable tr th:first-child div {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}
.usersListDataTable tr th:not(:first-child) {
    width: auto;
}

.usersListDataTable tbody tr:hover:not(:first-child) {
    background-color: var(--color-bg);
}
.usersListDataTable tbody tr:not(:last-child) {
    border-bottom: 1px solid var(--color-border);
}
.usersListDataTable tr th:not(:first-child) {
    padding: 10px;
    color: var(--color-dark-blue);
    vertical-align: middle;
    font-size: 16px;
}
.usersListDataTable tr:not(:first-child) {
    cursor: pointer;
}
.usersListDataTable th .nameContainer {
    display: inline-flex;
    flex-direction: column;
}
.usersListDataTable th .nameContainer span:first-child {
    font-weight: bold;
}
.usersListDataTable th .nameContainer span:nth-child(2) {
    font-weight: normal;
}
.usersListDataTable th .iconContainer {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.usersListDataTable th .addressContainer {
    display: inline-flex;
    flex-direction: column;
}
.usersListDataTable th .addressContainer span:first-child {
    font-weight: bold;
}
.usersListDataTable th .addressContainer span:nth-child(2) {
    font-weight: normal;
}





.listDataTable {
    display:table;
    width: 100%;
    table-layout: fixed;
}
.listDataTable tr:first-child th {
    font-weight: normal;
    font-size: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 10px;
    color: black;
}
.listDataTable tr th:first-child {
    padding-left: 10px;
}
.listDataTable tbody tr:not(:first-child) {
    cursor: pointer;
}
.listDataTable tbody tr:hover:not(:first-child) {
    background-color: var(--color-bg);
}
.listDataTable tbody tr:not(:last-child) {
    border-bottom: 1px solid var(--color-border);
}
.listDataTable tr th:not(:first-child) {
    padding: 10px;
    color: var(--color-dark-blue);
    vertical-align: middle;
    font-size: 15px;
}


.listDataTable tbody tr th div.iconContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}
.listDataTable tbody tr th .nameContainer {
    display: inline-flex;
    flex-direction: column;
}
.listDataTable tbody tr th .nameContainer span:first-child {
    font-weight: normal;
}
.listDataTable tbody tr th .nameContainer span:nth-child(2) {
    font-weight: normal;
}
.listDataTable tbody tr th .addressContainer {
    display: inline-flex;
    flex-direction: column;
}
.listDataTable tbody tr th .addressContainer span:first-child {
    font-weight: bold;
}
.listDataTable tbody tr th .addressContainer span:nth-child(2) {
    font-weight: normal;
}

.searchFieldContainer {
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: center;
    
    height: 35px;
    border: 1px solid #bbc7d0;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    
    background-color: white;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.searchFieldIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: normal;
    
    width: auto;
    height: 22px;
    cursor: pointer;
    color: #10355a;
}

.searchFieldIconContainer :hover {
    color: var(--blue)
}

.searchFieldText {
    border: none;
    outline: none;
    font-size: 18px;
    color: #10355a;
    width: 300px;
}

.searchFieldText :focus {
    border: none;
}

.searchFieldText::placeholder {
    font-size: 18px;
    color: var(--color-dark-grey);
}


.multiRow {
    white-space: pre-wrap;
}

.flex {
    display: flex;
}

.funcBarMenuContainer {
    position: relative;
}


.funcBarMenuBox {
    position: absolute;
    width: max-content;
    top: 40px;
    left: 0;
    display: flex;
    flex-direction: column;
    
    justify-content: flex-start;
    align-items: flex-start;
    
    border: 1px solid #bbc7d0;
    border-radius: 5px;
    cursor: pointer;
    color: #10355a;
    background-color: white;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    overflow: hidden;
}

.funcBarMenuItem {
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    width: 100%;
    
}

.funcBarMenuItem:not(:last-child) {
    border-bottom: 1px solid var(--color-border);
}

.funcBarMenuItem:hover {
    background-color: var(--color-bg);
    
}

.funcBarMenuButton {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: flex-start;
    align-items: center;
    height: 35px;
    gap: 5px;
    border: 1px solid #bbc7d0;
    border-radius: 5px;
    cursor: pointer;
    color: #10355a;
    background-color: white;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.funcBarMenuButton.chevron {
    font-size: 20px;
}

.funcBarMenuButton.active {
    border: 1px solid var(--color-dark-blue);
    background-color: var(--color-bg);
}

.funcBarMenuButton span {
    font-size: 16px;
    padding-right: 10px;
    padding-left: 5px;
}

.funcBarMenuButton:not(.active):hover {
    background-color: var(--color-bg);
    border: 1px solid hsl(206, 58%, 77%);
}

.contentErrorContainer {
    
    background-color: var(--color-red);
    display: flex;
    flex-direction: column;
    gap: 2px;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    margin-top: 10px;

}

.contentErrorContainer span {
    color: var(--color-bg);
    font-weight: normal;
    font-size: 12px;
}

.contentErrorContainer span:first-child {
    color: var(--color-bg);
    font-weight: bold;
    font-size: 18px;
}

.contentTabBar {
    background-color: white;
    display: flex;
    flex-direction: row;
    
    justify-content: flex-start;
    align-items: center;
    
    border-bottom: 1px solid var(--color-border);
}

.contentTabBar .item {
    display: flex;
    padding: 5px;
    cursor: pointer;
    border-bottom: 2px solid white;
}

.contentTabBar .item span {
    color: var(--color-dark-blue);
}
.contentTabBar .item.active {
    border-bottom: 2px solid var(--color-dark-blue);
    cursor: default;
}

.contentTabBar .item.active span {
    
}

.contentNoDataContainer {
    
    
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 10px;
}


.docuListContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
}

.docuList {
    
    width: 100%;
}
.docuList .row {
    display: table-row;
    height: 40px;
}
.docuList .row:hover {
    background-color: var(--color-bg);
}
.docuList .row:not(:last-child) {
    border-bottom: 1px solid var(--color-border);
}

.docuList .row .iconContainer {
    width: 50px;
}


.docuList .row .iconContainer .icon {
    width: 30px;
    height: 30px;
    color: var(--color-dark-blue);
}

.docuList .row .dateContainer {
    width: 300px;
}

.docuList .row .dateContainer span {
    color: var(--color-dark-blue);
    font-weight: normal;
    font-size: 15px;
}